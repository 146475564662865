import * as React from 'react';
import { Helmet } from 'react-helmet';

import { Game } from '../components/Game/index';
import Logo from '../components/Logo';

const AnswerPage = ({
	pageContext,
}: {
	pageContext: { question: { query: string } };
}) => {
	return (
		<>
			<Helmet>
				<title>Google Feud - Answers - ${pageContext.question.query}</title>
			</Helmet>
			<Logo />
			<Game staticQuery={pageContext.question.query} />
		</>
	);
};
export default AnswerPage;
